import {
  FrontendApi,
  IdentityApi,
  Configuration,
  ConfigurationParameters,
} from "@ory/kratos-client";

//server code
const sdk = () =>
  new FrontendApi(
    new Configuration({ basePath: process?.env.INTERNAL_KRATOS_URL })
  );

//server code
const adminSdk = () =>
  new IdentityApi(
    new Configuration({ basePath: process?.env.INTERNAL_KRATOS_ADMIN_URL })
  );

//browser code
const browserSdk = (options?: ConfigurationParameters) =>
  new FrontendApi(
    new Configuration({
      // basePath: process?.env?.KRATOS_BROWSER_URL,
      baseOptions: { withCredentials: true },
      ...options,
    })
  );

const kratos = {
  public: sdk,
  browser: browserSdk,
  admin: adminSdk,
};

export default kratos;
